<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">URL</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="searchOption.url"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">API 호출방법</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="searchOption.apiMethods"
                    :dataSource="dropdownApiMethod"
                    :allowFiltering="false"
                    :fields="{ text: 'comName', value: 'comCode' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
            <div class="section-header">
              <div class="header-right">
                <ul class="header-button">
                  <li class="confirm keyColor">
                    <erp-button
                        button-div="SAVE"
                        :is-shortcut-button="true"
                        :is-key-color="true"
                        :ignore="isPopupOpened"
                    >
                      저장
                    </erp-button>
                  </li>
                  <li class="add">
                    <erp-button
                        button-div="SAVE"
                        @click="addRecord"
                        :is-icon-custom="true"
                        :is-custom-shortcut-button="true"
                        shortcut-key="apiInfoAdd.shortcuts.addApiInfo"
                        :shortcut="{key: 'F3'}"
                        :ignore="isPopupOpened"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li class="delete">
                    <erp-button
                        button-div="DELETE"
                        :ignore="isPopupOpened"
                        :is-shortcut-button="true"
                    >
                      삭제
                    </erp-button>
                  </li>
                  <li class="refresh">
                    <erp-button
                        button-div="GET"
                        :is-icon-custom ="true"
                    >
                      초기화
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        button-div="FILE"
                        @click.native="onExcelButtonClicked"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="apiInfo"
                    v-bind="apiInfoOptions"
                    :dataSource="menuInfoDataSource"
                    @headerCellInfo="onHeaderCellInfo"
                    @queryCellInfo="onQueryCellInfo"
                />
              </div>
            </div>
          </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';
import inputText from "@/components/common/text/InputText.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton";

import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {getFormattedDate} from "@/utils/date";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";

export default {
  name: "ApiInfoAdd",
  components: {
    inputText,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOption: {
        url: "",
        apiMethods : ""
      },
      menuListDataSource:[],
      menuInfoDataSource:[{}],
    };
  },
  created() {},
  computed: {
    dropdownApiMethod() {
      const field = commonCodesGetCommonCode("API_METHOD", true);
      field.push({comName:"전체", comCode: ""});
      return field;
    },
    isPopupOpened() {
      return false;
    },
    apiInfoOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
          Edit,
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "ApiId",
            headerText: "API ID",
            type: "string",
            width: 90,
          },
          {
            field: "api",
            headerText: "API설명",
            type: "string",
            width: 90,
          },
          {
            field: "jobDiv",
            headerText: "업무구분",
            type: "string",
            width: 90,
            isCommonCodeField: true,
            groupCode: "JOB_DIV",
            editType: "dropdownedit",
          },
          {
            field: "ApiMethod",
            headerText: "API호출방법",
            type: "string",
            width: 90,
            isCommonCodeField: true,
            groupCode: "API_METHOD",
            editType: "dropdownedit",
          },
          {
            field: "a",
            headerText: "버전정보",
            type: "string",
            width: 90,
          },
          {
            field: "url",
            headerText: "URL",
            type: "string",
            width: 300,
          },
          {
            field: "b",
            headerText: "연결 프로그램 목록",
            type: "string",
            width: 300,
          },
          {
            field: "useFlag",
            headerText: '사용여부',
            displayAsCheckBox: true,
            textAlign: 'center',
            editType: "booleanedit",
            width: 60,
            type: 'boolean',
          },
        ],
      };
    },
  },
  mounted() {

  },
  methods: {
    addRecord() {
      this.$refs.apiInfo.addRecord({
        menuAuthDiv:null,
        ApiId:null,
        jobAuthDiv:null,
        urlPattrn:null,
        ApiMethod:null,
        description:null,
        useFlag:null,
        insertId:null,
        insertDt:getFormattedDate(new Date()),
        updateId:null,
        updateDt:null,
      });
      const data = this.$refs.apiInfo.getBatchCurrentViewRecords();

      this.$refs.apiInfo.editCell(data.length - 1, "caddieName");
    },
    onHeaderCellInfo(args) {
      const { cell:{ column:{ field } } } = args;
      // const { column:{ field } } = args;
      // console.log(field);
      console.log(args);
      if (
          field === "ApiId" ||
          field === "api" ||
          field === "jobDiv" ||
          field === "ApiMethod" ||
          field === "url" ||
          field === "useFlag"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo(args) {
      // const { column:{ field } } = args;
      // if (
      //     field === "ApiId" ||
      //     field === "api" ||
      //     field === "jobDiv" ||
      //     field === "ApiMethod" ||
      //     field === "url" ||
      //     field === "useFlag"
      // ) {
        args.cell.classList.add(this.$t('className.grid.modifyArea'));
      // }
    },
    onViewButtonClicked() {},
    onExcelButtonClicked() {
      this.$refs.apiInfo.excelExport();
    },
  },
};
</script>
